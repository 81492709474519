.footer {
  position: relative;
}
.footer span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
}
.footer__nav {
  width: 100%;
  max-width: 1280px;
  padding: 32px;
  margin: 0 auto;
  display: table;
  table-layout: fixed;
}
.footer__nav__box {
  vertical-align: top;
  display: table-cell;
}
.footer__nav__box:first-child {
  width: 240px;
}
.footer__nav__box__ttl {
  font-size: 16px;
  font-weight: bold;
}
.footer__nav__box__list {
  display: flex;
  flex-wrap: wrap;
}
.footer__nav__box__list__item {
  margin-top: 8px;
  margin-right: 32px;
}
.footer__nav__box__list__item a {
  text-decoration: underline;
}
.footer__inner {
  padding: 32px 0;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
.footer__info {
  opacity: 0.8;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.footer__info ul {
  margin-right: 32px;
}
.footer__info li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footer__info li + li {
  margin-top: 16px;
}
.footer__info li img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 4px;
}
.footer__menu {
  margin-left: auto;
}
.footer__menu ul {
  text-decoration: underline;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.footer__menu li {
  margin-left: 32px;
}
.footer__menu li a:hover {
  opacity: 0.8;
}
.footer__copy {
  opacity: 0.8;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #DBD4CE;
  text-align: center;
  padding: 32px 0;
  position: relative;
}
.footer__menu__lang {
  width: 128px;
  height: 32px;
  border-radius: 16px;
  padding-left: 16px;
  margin-top: 16px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}
.footer__menu__lang img {
  width: 20px;
  height: 20px;
}
.footer__menu__lang select {
  width: calc(100% - 32px);
  height: 100%;
  padding-left: 8px;
  cursor: pointer;
}
.footer__menu__lang option {
  color: #333;
}
@media screen and (max-width:768px) {
  .footer__inner {
    padding: 32px 16px;
    display: block;
  }
  .footer__inner ul + ul {
    margin-top: 16px;
  }
  .footer__info {
    display: block;
  }
  .footer__menu {
    margin-top: 32px;
    margin-left: 0;
  }
  .footer__menu ul {
    justify-content: flex-start;
  }
  .footer__menu li {
    margin-left: 0;
    margin-right: 32px;
  }
  .footer__copy {
    width: calc(100% - 32px);
    margin: 0 auto;
  }
  .footer__menu__lang {
    margin-left: 0;
  }
}
